@font-face {
  font-family: "Clear Sans";
  src: url("../src/assets/clear-sans.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}


body {
  display: flex;
  background-color: rgba(238, 230, 208, 1);
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "Clear Sans", sans-serif;
  font-size: 21px;
  overflow: hidden;
}

.text {
  order: 2;
  padding-top: 40px;
  width: 440px;
  font-weight: bold;
}

.board {
  order: 0;
  width: 440px;
  height: 440px;
  padding: 5px;
  background-color: #57407c;
  border-radius: 7px;
  outline: none;
  position: relative;
}

.board .cell,
.tile {
  user-select: none;
  cursor: default;
}

.details-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  margin: 15px auto;
  position: relative;
  width: 90%; 
  flex-direction: column; /* This will stack children vertically */
}

.fixed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 0px;
  top: 0;
  left: 50%;
  margin: 0 auto;
  margin-top: -15px;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.margin-right {
  margin-right: 20px;
}

.resetButton {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 7px;
  transition: color 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: #d3386a;
    color: rgba(255, 255, 255, 1);
  }

  @media only screen and (max-width: 500px) {
    font-size: 18px;
  }
}

.score-header {
  font-size: 50%;
}

.score-box {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  min-width: 50px;
  width: 70px;
  padding: 5px 15px;
  border-radius: 7px;
}

.footer-rules {
  margin: 15px;
  height: auto;
  position: relative;
}

.text {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  margin: 15px auto;
  margin-top: 20px;
  font-size: 16px;
  padding: 5px 15px;
  padding: 20px;
  width: 100vw; 
  border-radius: 7px;
  position: relative;

  & span {
    font-weight: 700;
  }
}

.rules-modal {
  width: 100vw;
  background-color: #57407c;
  color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 20px 60px rgba(0,0,0,0.3);
  border-radius: 0.7;
}

.buttons {
  display: flex;
  margin-right: 20px;
  justify-content: center; /* Horizontally centering the items */
  align-items: center; 
  gap: 20px;
  height: 100%;  
  margin-left: 20px;          /* Set the height to 100% if you want to center vertically */

}

.copyright {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 0 70px;
  margin: 0 auto;
  margin-top: 10xp;
  color: #3d2963;
}

small {
  font-weight: 700;
}

.cell,
.tile {
  width: 100px;
  height: 100px;
  margin: 5px;
  line-height: 90px;
  display: inline-block;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
  font-family: "Clear Sans";
  color: #766;
  background-color: #3d2963;
}

.tile0 {
  background-color: #dcb;
}

.tile2 {
  background-color: #eee;
  background-image: url(./assets/img/2.gif);
}

.tile4 {
  background-color: #eec;
  background-image: url(./assets/img/4.gif);
}

.tile8 {
  color: #ffe;
  background-image: url(./assets/img/8.gif);
}

.tile16 {
  color: #ffe;
  background-image: url(./assets/img/16.gif);
}

.tile32 {
  color: #ffe;
  background-image: url(./assets/img/32.gif);
}

.tile64 {
  color: #ffe;
  background-image: url(./assets/img/64.gif);
}

.tile128 {
  color: #ffe;
  background-image: url(./assets/img/128.gif);
}

.tile256 {
  color: #ffe;
  background-image: url(./assets/img/256.gif);
  font-size: 45px;
}

.tile512 {
  color: #ffe;
  background-image: url(./assets/img/512.gif);
  font-size: 45px;
}

.tile1024 {
  color: #fff;
  background-image: url(./assets/img/1024.gif);
  font-size: 35px;
}

.tile2048 {
  position: absolute;
  background-size: contain;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0px;
  color: #fff;
  background-image: url(./assets/img/2048.gif);
  font-size: 35px;
}

.try-again {
  z-index: 10;
  height: 100px;
  width: 100px;
  background-image: url(./assets/img/try-again.gif);
}

.tile {
  position: absolute;
}

.tile.merged {
  display: none;
}

.tile.merged.isMoving {
  display: inline;
}

.tile.new,
.overlay {
  animation-duration: 0.2s;
  animation-name: newTile;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  transform: scale(0);
}

@keyframes newTile {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0px;
  right: 0px;
  font-size: 55px;
  font-weight: bolder;
  border-radius: 7px;
}

.tryAgain {
  background-color: #876;
  color: #fff;
  height: 40px;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  border: 2px solid white;
}

.gameOver {
  background-size: contain;
  background-image: url(./assets/img/game-over.gif);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  text-align: center;
}

.overlay .message {
  color: #666;
}

/* Board.css */



/* Style for DirectionButton (assuming it's a simple button) */
.direction-button {
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  width: 100px;
  margin-left: 40px;
  margin-top: 20px;
  grid-template-rows: auto auto; /* Two rows */
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 10px; /* Adjust the gap between items if needed */
}

/* Style for items in the grid */
.grid-item {
  order: 2;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  width:  10px;
  height: 10px;
}

/* Make the first item span all three columns in the first row */
.grid-item:first-child {
  grid-column: 2 / span 2; /* Start from the second column and span 2 columns */
}

.grid-item:nth-child(2) {
  grid-column: 1; /* Start from the second column */
}

.keyboard{
  display: flex;
  column-gap: 50px;
  justify-content: space-between; /* Adjust as needed */
  align-items: center; /* Adjust as needed */
  padding: 10px;
}

.login-page {
  height: 100vh; /* 100% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Choose a font */
}

.login-page h1 {
  margin-bottom: 20px; /* Some spacing below the title */
}

/* Add more styling as needed */
/* Navbar.css */
.navbar {
  width: 100%;
  background-color: #333; /* Dark background color for navbar */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding for content inside navbar */
  font-family: 'Arial', sans-serif; /* Choose a font */
}

.navbar-brand {
  font-size: 24px; /* Example font size for the brand */
}


.sendtokenbutton {
  width: 230px;
  height: 50px;
  background-color: rgba(255, 107, 85, 1);
  box-shadow: 8px 10px 0px rgb(0, 0, 0);
  font-size:30px;
  transition: box-shadow 0.5s, transform 0.5s, color 0.3s, background-color 0.3s; /* Added transition property for transform */
  border:2px #333;
  border-radius: 5px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sendtokenbutton:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); /* Adjusted box-shadow values */
  transform: translate(8px, 10px); /* Moved the button right and down */
  cursor: pointer;
  color: #fffbfb;
  background-color: rgb(247, 68, 40);

}



.connectbutton:hover{
color: white;
}